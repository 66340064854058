/**
 * @format
 */
import React, {Component} from "react";
import {observer} from "mobx-react"
import styled from "styled-components"
import Flex, {FlexItem} from "styled-flex-component";

class TxInProgress extends Component {
    render () {
        return (
            <div>TxInProgress</div>
        )
    }
}

export default observer(TxInProgress)